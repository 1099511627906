import React, { FC, ReactNode } from 'react'
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'

const primary = {
  main: '#0191A5',
  dark: '#005B6A',
  light: '#85E3E3',
}
const secondaryColor = red[600]

const theme = createMuiTheme({
  palette: {
    type: 'light',
    secondary: {
      main: secondaryColor,
    },
    primary,
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
  },
  typography: {
    body2: {
      fontSize: '0.875rem',
      fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    body1: {
      fontSize: '1rem',
      fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 'bold',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    h2: {
      fontSize: 24,
      fontFamily: `'Overpass', Helvetica, Arial, sans-serif`,
      fontWeight: 700,
      lineHeight: '38px',
    },
    h3: {
      fontSize: 24,
      fontFamily: `'Overpass', Helvetica, Arial, sans-serif`,
      fontWeight: 700,
      lineHeight: '38px',
    },
    h5: {
      fontSize: '16px',
      fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 500,
      lineHeight: 1.334,
      letterSpacing: '-0.05px',
    },
    h6: {
      fontSize: '14px',
      fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: 120,
        '@media (min-width: 0px) and (orientation: landscape)': {
          minHeight: 120,
        },
        '@media (min-width: 600px)': {
          minHeight: 120,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(133, 227, 227, .4)',
        fontFamily: `'Lato', Helvetica, Arial, sans-serif`,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '18px',
        borderRight: '1px solid rgba(133, 227, 227, .4)',
        '&:last-child': {
          borderRight: 'none',
        },
      },
      head: {
        fontFamily: `'Overpass', Helvetica, Arial, sans-serif`,
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '30px',
      },
    },
    MuiTable: {
      root: {
        margin: '20px 0',
      },
    },
    MuiSelect: {
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: secondaryColor,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: secondaryColor,
          },
        },
      },
      notchedOutline: {
        borderColor: 'rgba(255, 255, 255, 0.23)',
      },
    },
    MuiAvatar: {
      colorDefault: {
        color: primary.main,
        backgroundColor: grey[600],
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 1,
      },
    },
    MuiCard: {
      root: {
        margin: 16,
      },
    },
    MuiCardContent: {
      root: {
        padding: 20,
        '&:last-child': {
          paddingBottom: 20,
        },
      },
    },
  },
})

export interface ThemeProviderProps {
  readonly children: ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
}: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
)

export default ThemeProvider
