import React, { FC, ReactNode } from 'react'
import Button from '@material-ui/core/Button'
import { NavLink } from 'react-router-dom'
import { useStyles } from './useStyles'

interface ItemProps {
  readonly to: string
  readonly exact?: boolean
  readonly title?: ReactNode
}

const Item: FC<ItemProps> = ({ title, to, exact }: ItemProps) => {
  const classes = useStyles()
  return (
    <Button className={classes.item} component={NavLink} to={to} exact={exact}>
      {title}
    </Button>
  )
}

Item.defaultProps = {
  exact: true,
}

export default Item
