import { PayloadAction, Action } from 'redux'
import { RootState } from 'store'

export const TOGGLE_POPOVER = 'MainHeader/TOGGLE_POPOVER'
export const LOGGING_OUT_BEGIN = 'MainHeader/LOGGIN_OUT_BEGIN'
export const LOGGING_OUT_SUCCESS = 'MainHeader/LOGGIN_OUT_SUCCESS'
export const LOGGING_OUT_FAILED = 'MainHeader/LOGGIN_OUT_FAILED'
export const RESET_ERROR = 'MainHeader/RESET_ERROR'
export const SET_LOADING = 'MainHeader/SET_LOADING'

export type TogglePopover = PayloadAction<
  typeof TOGGLE_POPOVER,
  {
    readonly popoverAnchorEl: HTMLElement | null
  }
>

export type LoggingOutBegin = Action<typeof LOGGING_OUT_BEGIN>

export type LoggingOutSuccess = Action<typeof LOGGING_OUT_SUCCESS>

export type LoggingOutFailed = PayloadAction<
  typeof LOGGING_OUT_FAILED,
  {
    readonly errorMessage: string
  }
>

export type ResetError = Action<typeof RESET_ERROR>

export type SetLoading = PayloadAction<
  typeof SET_LOADING,
  { readonly loading: boolean }
>

export type MainHeaderAction =
  | TogglePopover
  | LoggingOutBegin
  | LoggingOutSuccess
  | LoggingOutFailed
  | ResetError
  | SetLoading

export interface MainHeaderState {
  readonly popoverAnchorEl: HTMLElement | null
  readonly loading: boolean
  readonly errorMessage: false | string
}

export interface MainHeaderRootState extends RootState {
  readonly mainHeader: MainHeaderState
}
