import React, { FunctionComponent, memo } from 'react'
import { useDispatch } from 'react-redux'
import AlertDialog from '../../components/AlertDialog'
import reducer from './reducer'
import saga from './saga'
import { loggingOutBegin } from './actions'
import { useInjectReducer } from 'hooks/useInjectReducer'
import { useInjectSaga } from 'hooks/useInjectSaga'

export interface LogoutProps {
  readonly showConfirmation: boolean
  readonly onDismissConfirmation: () => void
}

const Logout: FunctionComponent<LogoutProps> = ({
  showConfirmation,
  onDismissConfirmation,
}: LogoutProps) => {
  useInjectReducer({ key: 'logout', reducer })
  useInjectSaga({ key: 'logout', saga })

  const dispacth = useDispatch()

  const handleLogout = () => {
    onDismissConfirmation()
    dispacth(loggingOutBegin())
  }

  return (
    <>
      {showConfirmation && (
        <AlertDialog
          open={showConfirmation}
          onClose={onDismissConfirmation}
          description="Exit?"
          positiveText="Ok"
          onPositive={handleLogout}
          negativeText="Cancel"
          onNegative={onDismissConfirmation}
        />
      )}
    </>
  )
}

export default memo(Logout)
