import { makeStyles } from '@material-ui/core/styles'

interface StyleProps {
  readonly color?: string
  readonly opacity?: string
}

export const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: ({ color }: StyleProps) =>
      color || theme.palette.primary.main,
    opacity: ({ opacity }: StyleProps) => opacity,
  },
}))
