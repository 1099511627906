import { combineReducers, Reducer } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import sessionReducer from '../containers/SessionProvider/reducer'
import type { SessionProviderState } from 'containers/SessionProvider/types'
import history from '@history'

export interface InjectedReducers {
  [k: string]: Reducer
}

export interface RootState {
  readonly router: RouterState
  readonly session: SessionProviderState
  readonly [k: string]: unknown
}

export type RootReducer = Reducer<RootState>

const createReducer = (injectedReducers: InjectedReducers): RootReducer =>
  combineReducers({
    router: connectRouter(history),
    session: sessionReducer,
    ...injectedReducers,
  })

export default createReducer
