/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios'
import { TOKEN } from './constants'

interface MClient extends AxiosInstance {
  tryRequest: <T = any>(
    callback: (client: HttpClient) => Promise<AxiosResponse<T>>
  ) => Promise<Response<T>>
}

interface ErrorResponseBody {
  readonly status: {
    readonly code: number
    readonly message?: string
  }
  readonly message?: string
  readonly details?: {
    readonly body?: any
    readonly cookies?: any
    readonly headers?: any
    readonly params?: any
    readonly query?: any
    readonly signedCookies?: any
    readonly stack?: string
  }
}

const baseURL = process.env.REACT_APP_REST_API_LOCATION

const internal: MClient = axios.create({
  baseURL,
  withCredentials: true,
}) as any

const { interceptors } = internal

export interface ResponseError {
  readonly isHttpClientError?: true
  readonly code: number
  readonly message: string
}

export type Response<D = unknown> =
  | {
      readonly data: D
      readonly error?: undefined
    }
  | {
      readonly data?: undefined
      readonly error: ResponseError
    }

export const tryRequest = async <D>(
  callback: Promise<AxiosResponse<D>>
): Promise<Response<D>> => {
  try {
    const { data } = await callback
    return { data }
  } catch (e) {
    const error: ResponseError = e
    if (error.isHttpClientError) {
      return { error }
    } else if (e instanceof Error) {
      return { error: { code: 1, message: e.message } }
    } else if (e) {
      return { error: { code: 1, message: JSON.stringify(e) } }
    } else {
      return { error: { code: 1, message: 'Unknown Error' } }
    }
  }
}

internal.tryRequest = (callback) => {
  return tryRequest(callback(internal))
}

const errorInterceptor = async (e: any) => {
  const error: AxiosError<ErrorResponseBody> = e
  if (error.isAxiosError) {
    if (error.response) {
      return Promise.reject({
        isHttpClientError: true,
        ...error.response.data,
      })
    }
    return Promise.reject({
      isHttpClientError: true,
      status: {
        code: error.request ? 3 : 2,
        message: error.message,
      },
    })
  }
  return Promise.reject({
    isHttpClientError: true,
    status: {
      code: 1,
      message: error.message,
    },
  })
}

interceptors.response.use(undefined, errorInterceptor)
interceptors.request.use((value) => {
  const token = localStorage.getItem(TOKEN)
  if (token && value.headers) {
    const headers = value.headers as Record<string, string>
    headers['Authorization'] = `Bearer ${token}`
  }
  return value
}, errorInterceptor)

export type HttpClient = Readonly<
  Pick<
    MClient,
    | 'request'
    | 'get'
    | 'delete'
    | 'head'
    | 'options'
    | 'post'
    | 'put'
    | 'patch'
    | 'tryRequest'
  >
>

const client: HttpClient = internal

export default client
