import React, { FC, ReactElement } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import MainHeader from 'containers/MainHeader'
import SidebarMenu from 'containers/SidebarMenu'
import Container from '@material-ui/core/Container'
import Category from 'contexts/CategoryContext'
import useStyles from './useStyles'

export interface MainPageWrapperProps {
  readonly children: ReactElement
}

const MainPageWrapper: FC<MainPageWrapperProps> = ({
  children,
}: MainPageWrapperProps) => {
  const classes = useStyles()
  return (
    <Category>
      <div className={classes.root}>
        <MainHeader />
        <SidebarMenu />
        <div className={classes.content}>
          <main className={classes.contentWrapper}>
            <Toolbar />
            <Container className="container">{children}</Container>
          </main>
        </div>
      </div>
    </Category>
  )
}

export default MainPageWrapper
