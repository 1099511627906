import React, { FC, ComponentType, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom'
import { stringify } from 'query-string'
import type { RootState } from 'store'
import type { SessionInitialization } from 'containers/SessionProvider/types'
import FullProgress from 'components/FullProgress'

const isProgress = (initialization: SessionInitialization) =>
  initialization === 'uninitialized' || initialization === 'initializing'

const PrivateRoute: FC<RouteProps> = ({
  component,
  render,
  ...props
}: RouteProps) => {
  const { initialization, user } = useSelector(
    ({ session }: RootState) => session
  )
  const renderCallback = useCallback(
    (p: RouteComponentProps) => {
      if (initialization === 'initialized' && user) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const Component = component as ComponentType<any>
        return render ? render(p) : <Component {...p} />
      }
      if (isProgress(initialization)) {
        return <FullProgress />
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            search: `?${stringify({ from: p.location.pathname })}`,
            state: { from: p.location },
          }}
        />
      )
    },
    [initialization, render, component, user]
  )
  return <Route {...props} render={renderCallback} />
}

export default PrivateRoute
