import { call, put, takeEvery } from 'redux-saga/effects'
import type { SagaIterator } from 'redux-saga'
import { loggingOut } from './services'
import type { Response } from 'utils/axios'
import { loggingOutSuccess, loggingOutFailed } from './actions'
import { removeSessionAction } from 'containers/SessionProvider/actions'
import { push } from 'connected-react-router'
import { LOGGING_OUT_BEGIN } from './types'

export function* logout(): SagaIterator {
  const { error }: Response = yield call(loggingOut)
  if (!error) {
    yield put(loggingOutSuccess())
    yield put(removeSessionAction())
    yield put(push('/login'))
  } else {
    yield put(loggingOutFailed(error.message))
  }
}

export default function* saga(): SagaIterator {
  yield takeEvery(LOGGING_OUT_BEGIN, logout)
}
