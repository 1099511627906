import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& > .container': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))
