import React, { FC, ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import type { RootState } from 'store'
import type { SessionInitialization } from 'containers/SessionProvider/types'
import FullProgress from 'components/FullProgress'

const isProgress = (initialization: SessionInitialization) =>
  initialization === 'uninitialized' || initialization === 'initializing'

const NoAuthRoute: FC<RouteProps> = ({
  component,
  render,
  ...props
}: RouteProps) => {
  const { initialization, user } = useSelector(
    ({ session }: RootState) => session
  )
  return (
    <Route
      {...props}
      render={(p) => {
        if (initialization === 'uninitialized' || !user) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const Component = component as ComponentType<any>
          return render ? render(p) : <Component {...p} />
        }
        if (isProgress(initialization)) {
          return <FullProgress />
        }
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }}
    />
  )
}

export default NoAuthRoute
