import React, { FC, useCallback } from 'react'
import type { RouteProps, RouteComponentProps } from 'react-router-dom'
import PrivateRoute from './index'
import MainPageWrapper from 'containers/MainPageWrapper'

export type MainPrivateRouteProps = Omit<RouteProps, 'render'>

const MainPrivateRoute: FC<MainPrivateRouteProps> = ({
  component,
  ...props
}: MainPrivateRouteProps) => {
  const render = useCallback(
    (p: RouteComponentProps) => {
      const Component = component as FC<RouteComponentProps>
      return (
        <MainPageWrapper key="main-page-wrapper">
          <Component {...p} />
        </MainPageWrapper>
      )
    },
    [component]
  )
  return <PrivateRoute {...props} render={render} />
}

export default MainPrivateRoute
