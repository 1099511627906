import React, { FC, ReactNode, MouseEventHandler } from 'react'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useStyles, AccordionSummary, Accordion } from './useStyles'

interface MenuProps {
  readonly children?: ReactNode
  readonly title?: ReactNode
  readonly icon?: ReactNode
  readonly onClick?: MouseEventHandler<HTMLDivElement>
}

const Menu: FC<MenuProps> = ({ title, icon, children, onClick }: MenuProps) => {
  const classes = useStyles()
  const item = (
    <ListItem button onClick={onClick}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </ListItem>
  )
  if (!children) {
    return <div className={classes.menu}>{item}</div>
  }
  return (
    <Accordion elevation={0} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {item}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default Menu
