import React, { FC, ReactNode } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import Alert from '@material-ui/lab/Alert'

export interface AlertDialogProps {
  readonly open: boolean
  readonly title?: string
  readonly description?: string
  readonly content?: ReactNode
  readonly onClose?: () => void
  readonly positiveText?: string
  readonly onPositive?: () => void
  readonly negativeText?: string
  readonly onNegative?: () => void
  readonly loading?: boolean
  readonly errorMessage?: false | string
  readonly onErrorAlertClose?: () => void
}

const AlertDialog: FC<AlertDialogProps> = ({
  open,
  title,
  description,
  content,
  onClose,
  positiveText,
  onPositive,
  negativeText,
  onNegative,
  loading = false,
  errorMessage = false,
  onErrorAlertClose,
}: AlertDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby={title ? 'alert-dialog-title' : 'alert-dialog-description'}
    aria-describedby="alert-dialog-description"
  >
    {loading && <LinearProgress />}
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    <DialogContent>
      {errorMessage && (
        <Alert
          style={{ marginBottom: '8px' }}
          severity="error"
          onClose={onErrorAlertClose}
        >
          {errorMessage}
        </Alert>
      )}
      {description && (
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      )}
      {content}
    </DialogContent>
    <DialogActions>
      {negativeText && (
        <Button onClick={onNegative} color="secondary" disabled={loading}>
          {negativeText}
        </Button>
      )}
      {positiveText && (
        <Button onClick={onPositive} color="secondary" disabled={loading}>
          {positiveText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
)

export default AlertDialog
