import { FC, useEffect, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initSessionAction } from './actions'
import type { RootState } from 'store'

export interface SessionProviderProps {
  readonly children: ReactElement
}

const selector = ({ session }: RootState) => session

const SessionProvider: FC<SessionProviderProps> = ({
  children,
}: SessionProviderProps) => {
  const { initialization } = useSelector(selector)
  const dispatch = useDispatch()
  useEffect(() => {
    if (initialization === 'uninitialized') {
      dispatch(initSessionAction())
    }
  }, [dispatch, initialization])
  return children
}

export default SessionProvider
