import { all, call, put, takeLeading, takeEvery } from 'redux-saga/effects'
import type { SagaIterator } from 'redux-saga'
import client, { Response } from 'utils/axios'
import { TOKEN } from 'utils/constants'
import type { SetSessionAction } from './types'
import {
  INIT_SESSION_ACTION,
  SET_SESSION_ACTION,
  REMOVE_SESSION_ACTION,
} from './constants'
import { setSessionAction, removeSessionAction } from './actions'
import { makeGetProfile } from './services'

export function* initSession(): SagaIterator {
  const getProfile = makeGetProfile(client)
  const { data } = (yield call(getProfile)) as Response<UserSession>
  if (
    data &&
    (data.role === 'admin' ||
      data.role === 'super_admin' ||
      data.role === 'agent_admin' ||
      data.role === 'agent')
  ) {
    yield put(setSessionAction(data))
  } else {
    yield put(removeSessionAction())
  }
}

const setToken = (token: string) => localStorage.setItem(TOKEN, token)

export function* setSession({ payload }: SetSessionAction): SagaIterator {
  if (payload.token) {
    yield call(setToken, payload.token)
  }
}

const removeToken = () => localStorage.removeItem(TOKEN)

export function* removeSession(): SagaIterator {
  yield call(removeToken)
}

export default function* sagas(): SagaIterator {
  yield all([
    takeLeading(INIT_SESSION_ACTION, initSession),
    takeEvery(SET_SESSION_ACTION, setSession),
    takeEvery(REMOVE_SESSION_ACTION, removeSession),
  ])
}
