import React, { FC } from 'react'
import MuiDivider, {
  DividerProps as MuiDividerProps,
} from '@material-ui/core/Divider'
import { useStyles } from './useStyles'

export interface DividerProps {
  readonly color?: string
  readonly opacity?: string
  readonly variant?: MuiDividerProps['variant']
}

const DividerComponent: FC<DividerProps> = (props: DividerProps) => {
  const classes = useStyles(props)
  return <MuiDivider variant={props.variant} className={classes.divider} />
}

export default DividerComponent
