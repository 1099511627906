import React, { FC, useState, useCallback, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import useTheme from '@material-ui/core/styles/useTheme'
import Paper from '@material-ui/core/Paper'
import type { Theme } from '@material-ui/core/styles'
import GroupOutlined from '@material-ui/icons/GroupOutlined'
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined'
import HowToVoteOutlinedIcon from '@material-ui/icons/HowToVoteOutlined'
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined'
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Divider from 'components/Divider'
import { useSession } from 'hooks/useSession'
import { CategoryContext } from 'contexts/CategoryContext'
import Menu from '../Menu'
import Item from '../Item'
import Logout from '../../Logout'
import { useStyles } from './useStyles'

const MenuList: FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const category = useContext(CategoryContext)
  const dismissConfirmation = useCallback(() => setShowConfirmation(false), [
    setShowConfirmation,
  ])
  const { palette } = useTheme<Theme>()
  const session = useSession()
  const classes = useStyles()
  if (!session) {
    return <div />
  }
  return (
    <Paper elevation={2} className={classes.paper}>
      <div style={{ padding: 16 }}>
        <Typography variant="h2">
          {`${session.name} (${session.role})`}
        </Typography>
      </div>
      <Divider variant="middle" color={palette.primary.main} />
      <Menu title="Users" icon={<GroupOutlined />}>
        {session.role === 'admin' || session.role === 'super_admin' ? (
          <>
            <Item title="Users List" to="/user" />
          </>
        ) : (
          <>
            <Item title="Users Detail" to="/user-detail" />
          </>
        )}
      </Menu>

      <Divider variant="middle" color={palette.primary.light} opacity="0.2" />

      {session.role === 'admin' || session.role === 'super_admin' ? (
        <>
          <Menu title="Reseller" icon={<HowToVoteOutlinedIcon />}>
            <Item title="Reseller List" to="/reseller" />
            <Item title="Reseller Locations" to="/reseller-location" />
            <Item title="Reseller Registration" to="/reseller-registration" />
          </Menu>
        </>
      ) : null}

      <Divider variant="middle" color={palette.primary.light} opacity="0.2" />

      <Menu title="Products" icon={<LibraryAddOutlinedIcon />}>
        {session.role === 'admin' || session.role === 'super_admin' ? (
          <>
            <Item title="Product List" to="/product" />
            <Item title="Product Colors" to="/color" />
            <Item title="Categories" to={`/category/${category.tab}`} />
            <Item title="Reseller's Products" to="/product-reseller" />
          </>
        ) : (
          <>
            <Item title="Product List" to="/product" />
            <Item title="My Products" to="/product-reseller" />
          </>
        )}
      </Menu>
      <Divider variant="middle" color={palette.primary.light} opacity="0.2" />
      {session.role === 'admin' || session.role === 'super_admin' ? (
        <>
          <Menu title="Gallery" icon={<PermMediaOutlinedIcon />}>
            <Item title="Gallery Categories" to="/gallery-category" />
            <Item title="Gallery List" to="/gallery" />
          </Menu>
        </>
      ) : (
        <>
          <Menu title="Courier" icon={<PermMediaOutlinedIcon />}>
            <Item title="Courier List" to="/agent-courier" />
          </Menu>
        </>
      )}

      <Divider variant="middle" color={palette.primary.light} opacity="0.2" />
      {session.role === 'admin' || session.role === 'super_admin' ? (
        <Menu title="Transactions" icon={<AccountBalanceWalletOutlinedIcon />}>
          <Item title="Statistic" to="/statistic" />
          <Item title="Orders" to="/order" />
          <Item title="Shipments" to="/shipment" />
          <Item title="Customers" to="/customer" />
          <Item title="Coupon" to="/coupon" />
          <Item title="Courier" to="/courier" />
          <Item title="Payment Method" to="/payment-method" />
        </Menu>
      ) : (
        <Menu title="Transactions" icon={<AccountBalanceWalletOutlinedIcon />}>
          <Item title="All Order" to="/shipment" />
          <Item title="Waiting Payment" to="/shipment/waiting/list" />
          <Item title="Paid" to="/shipment/paid/list" />
          <Item title="Order Processed" to="/shipment/process/list" />
          <Item title="Order Delivery" to="/shipment/sent/list" />
          <Item title="Order Delivered" to="/shipment/delivered/list" />
          <Item title="Completed Order" to="/shipment/completed/list" />
          <Item title="Notification" to="/" />
        </Menu>
      )}
      <Divider variant="middle" color={palette.primary.light} opacity="0.2" />
      {(session.role === 'admin' || session.role === 'super_admin') && (
        <>
          <Menu title="Images" icon={<AccountBalanceWalletOutlinedIcon />}>
            <Item title="Image List" to="/image" />
          </Menu>
          <Divider
            variant="middle"
            color={palette.primary.light}
            opacity="0.2"
          />
        </>
      )}
      <Menu
        title="Logout"
        icon={<ExitToApp />}
        onClick={() => setShowConfirmation(true)}
      />
      <Logout
        showConfirmation={showConfirmation}
        onDismissConfirmation={dismissConfirmation}
      />
    </Paper>
  )
}

export default MenuList
