import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: 325,
  },
  paper: {
    width: 325,
    border: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(1),
  },
}))
