import React, { FC } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from './useStyles'

const FullProgress: FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

export default FullProgress
