import React, { ReactNode, FunctionComponent } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import useStyles from './useStyles'

export interface ProgressOverlayProps {
  readonly children?: ReactNode
  readonly progressSize?: number | string
  readonly visible?: boolean
  readonly fullWidth?: boolean
}

const ProgressOverlay: FunctionComponent<ProgressOverlayProps> = (
  props: ProgressOverlayProps
) => {
  const { children, progressSize, visible } = props
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      {children}
      {visible && (
        <div className={classes.overlay}>
          <CircularProgress size={progressSize} />
        </div>
      )}
    </div>
  )
}

ProgressOverlay.defaultProps = {
  fullWidth: true,
}

export default ProgressOverlay
