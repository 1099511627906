import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export const tabs = [
  'material',
  'usage',
  'category',
  'department',
  'color',
  'style',
] as const

const isTab = (tab?: string | null): boolean => {
  return tabs.some((t) => t === tab)
}

const getTab = (): Tab => {
  const tab = localStorage.getItem('category')
  if (isTab(tab)) {
    return tab as Tab
  }
  return 'usage'
}

export type Tab = typeof tabs[number]

export interface CategoryContextProps {
  readonly tab: Tab
}

export const CategoryContext = React.createContext<CategoryContextProps>({
  tab: getTab(),
})

export interface CategoryContextProviderProps {
  readonly children: ReactNode
}

const CategoryContextProvider: FC<CategoryContextProviderProps> = ({
  children,
}: CategoryContextProviderProps) => {
  const [t, setT] = useState<CategoryContextProps>({
    tab: getTab(),
  })
  const { location } = useHistory()
  useEffect(() => {
    if (location.pathname.startsWith('/category/')) {
      const tab = location.pathname.replaceAll(/\/category\/|\/.*/gi, '') as Tab
      if (isTab(tab)) {
        setT({ tab })
        localStorage.setItem('category', tab)
      }
    }
  }, [location.pathname, setT])
  return (
    <CategoryContext.Provider value={t}>{children}</CategoryContext.Provider>
  )
}

export default CategoryContextProvider
