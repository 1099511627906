import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.dark,
  },
  logo: {
    '& > img': {
      width: 180,
      height: 80,
      objectFit: 'contain',
    },
  },
  buttonPopover: {
    color: 'inherit',
    border: 0,
    cursor: 'pointer',
    margin: 0,
    display: 'inline-flex',
    outline: 0,
    padding: 0,
    position: 'relative',
    alignItems: 'center',
    userSelect: 'none',
    borderRadius: 0,
    verticalAlign: 'middle',
    '-moz-appearance': 'none',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '-webkit-appearance': 'none',
    '-webkit-tap-highlight-color': 'transparent',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    width: '200px',
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
    },
  },
  linearProgress: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}))
