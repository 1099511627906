import {
  MainHeaderState,
  MainHeaderAction,
  TOGGLE_POPOVER,
  LOGGING_OUT_BEGIN,
  LOGGING_OUT_SUCCESS,
  LOGGING_OUT_FAILED,
  RESET_ERROR,
  SET_LOADING,
} from './types'

export const defaultState: MainHeaderState = {
  popoverAnchorEl: null,
  loading: false,
  errorMessage: false,
}

const reducer = (
  state = defaultState,
  action: MainHeaderAction
): MainHeaderState => {
  switch (action.type) {
    case TOGGLE_POPOVER:
      return {
        ...state,
        ...action.payload,
      }
    case LOGGING_OUT_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case LOGGING_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        popoverAnchorEl: null,
      }
    case LOGGING_OUT_FAILED:
      return {
        ...state,
        loading: false,
        popoverAnchorEl: null,
        ...action.payload,
      }
    case RESET_ERROR:
      return {
        ...state,
        errorMessage: false,
      }
    case SET_LOADING:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default reducer
