import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles, withStyles } from '@material-ui/core/styles'

export const Accordion = withStyles({
  root: {
    border: 'none',
    margin: '16px 0',
    minHeight: '0',
    '&$expanded': {
      minHeight: '0',
      margin: '16px 0',
    },
    '&:before': {
      height: 0,
    },
  },
  expanded: {
    margin: '16px 0',
  },
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    border: 'none',
    margin: '0 12px 0 0',
    minHeight: '0',
    minWidth: 0,
    padding: 0,
    justifyContent: 'start',
    '&$expanded': {
      minHeight: '0',
      margin: '0 12px 0 0',
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(1)}px 0 48px`,
  },
  icon: {
    minWidth: 40,
  },
  menu: {
    margin: '16px 0',
  },
}))
