/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense, ComponentType, FunctionComponent } from 'react'

export interface ImportFunction<
  P = any,
  T extends ComponentType<P> = ComponentType<P>
> {
  (): Promise<{ default: T }>
}

const Lazy = <
  P extends any = any,
  T extends ComponentType<P> = ComponentType<P>
>(
  importFunc: ImportFunction<P, T>,
  { fallback = null } = { fallback: null }
): FunctionComponent<P> => {
  const LazyComponent: ComponentType<any> = lazy(importFunc)
  const Loadable: FunctionComponent<P> = (props: P) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  )
  return Loadable
}

export default Lazy
