import React, { FC } from 'react'
import { Switch } from 'react-router-dom'
import MainPrivateRoute from 'containers/PrivateRoute/Main'
import NoAuthRoute from 'containers/NoAuthRoute'
import HomePage from 'containers/HomePage/Loadable'
import ImageListPage from 'containers/ImageListPage/Loadable'
import LoginPage from 'containers/LoginPage/Loadable'
import CourierListPage from 'containers/CourierListPage/Loadable'
import OrderListPage from 'containers/OrderListPage/Loadable'
import OrderDetailPage from 'containers/OrderDetailPage/Loadable'
import ProductListPage from 'containers/ProductListPage/Loadable'
import PaymentListPage from 'containers/PaymentListPage/Loadable'
import ResellerList from 'containers/ResellerList/Loadable'
import UserListPage from 'containers/UserListPage/Loadable'
import GalleryListPage from 'containers/GalleryListPage/Loadable'
import CustomerListPage from 'containers/CustomerListPage/Loadable'
import GalleryCategoryPage from 'containers/GalleryCategoryPage/Loadable'
import ResellerLocationPage from 'containers/ResellerLocationPage/Loadable'
import CouponListPage from 'containers/CouponListPage/Loadable'
import ColorListPage from 'containers/ColorListPage/Loadable'
import ProductDetailPage from 'containers/ProductDetailPage/Loadable'
import GalleryDetailPage from 'containers/GalleryDetailPage/Loadable'
import CouponDetailPage from 'containers/CouponDetailPage/Loadable'
import ShipmentListPage from 'containers/ShipmentListPage/Loadable'
import CategoriesPage from 'containers/CategoriesPage/Loadable'
import ShipmentDetailPage from 'containers/ShipmentDetailPage/Loadable'
import ProductListPageReseller from 'containers/ProductListPageReseller/Loadable'
import ProductDetailResellerPage from 'containers/ProductDetailResellerPage/Loadable'
import UserDetalPage from 'containers/UserDetailPage/Loadable'
import PaymentMethodListPage from 'containers/PaymentMethodListPage/Loadable'
import AgentCourierListPage from 'containers/AgentCourierListPage/Loadable'
import StatiscticPage from 'containers/StatisticPage/Loadable'
import ForgotPasswordPage from 'containers/ForgotPasswordPage/Loadable'
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable'
import AccountPage from 'containers/AccountPage/Loadable'
import ResellerRegistrationPage from 'containers/ResellerRegistrationPage/Loadable'

const Router: FC = () => (
  <Switch>
    <MainPrivateRoute exact path="/" component={HomePage} />
    <MainPrivateRoute exact path="/courier" component={CourierListPage} />
    <MainPrivateRoute
      exact
      path="/agent-courier"
      component={AgentCourierListPage}
    />
    <MainPrivateRoute exact path="/order/:id" component={OrderDetailPage} />
    <MainPrivateRoute exact path="/order" component={OrderListPage} />
    <MainPrivateRoute exact path="/product" component={ProductListPage} />
    <MainPrivateRoute exact path="/color" component={ColorListPage} />
    <MainPrivateRoute exact path="/payment" component={PaymentListPage} />
    <MainPrivateRoute exact path="/reseller" component={ResellerList} />
    <MainPrivateRoute exact path="/user" component={UserListPage} />
    <MainPrivateRoute exact path="/user-detail" component={UserDetalPage} />
    <MainPrivateRoute exact path="/gallery" component={GalleryListPage} />
    <MainPrivateRoute exact path="/customer" component={CustomerListPage} />
    <MainPrivateRoute exact path="/image" component={ImageListPage} />
    <MainPrivateRoute
      exact
      path="/gallery-category"
      component={GalleryCategoryPage}
    />
    <MainPrivateRoute
      exact
      path="/reseller-location"
      component={ResellerLocationPage}
    />
    <MainPrivateRoute
      exact
      path="/reseller-registration"
      component={ResellerRegistrationPage}
    />
    <MainPrivateRoute exact path="/coupon" component={CouponListPage} />
    <MainPrivateRoute exact path="/product/:id" component={ProductDetailPage} />
    <MainPrivateRoute exact path="/gallery/:id" component={GalleryDetailPage} />
    <MainPrivateRoute exact path="/coupon/:id" component={CouponDetailPage} />
    <MainPrivateRoute exact path="/shipment" component={ShipmentListPage} />
    <MainPrivateRoute exact path="/category/usage" component={CategoriesPage} />
    <MainPrivateRoute
      exact
      path="/category/material"
      component={CategoriesPage}
    />
    <MainPrivateRoute
      exact
      path="/category/category"
      component={CategoriesPage}
    />
    <MainPrivateRoute
      exact
      path="/category/department"
      component={CategoriesPage}
    />
    <MainPrivateRoute exact path="/category/color" component={CategoriesPage} />
    <MainPrivateRoute exact path="/category/style" component={CategoriesPage} />
    <MainPrivateRoute
      exact
      path="/shipment/:id"
      component={ShipmentDetailPage}
    />
    <MainPrivateRoute
      exact
      path="/product-reseller"
      component={ProductListPageReseller}
    />
    <MainPrivateRoute
      exact
      path="/product-reseller/:id"
      component={ProductDetailResellerPage}
    />
    <MainPrivateRoute
      exact
      path="/shipment/:state/list"
      component={ShipmentListPage}
    />
    <MainPrivateRoute
      exact
      path="/payment-method"
      component={PaymentMethodListPage}
    />
    <MainPrivateRoute exact path="/statistic" component={StatiscticPage} />
    <MainPrivateRoute exact path="/account" component={AccountPage} />

    <NoAuthRoute exact path="/login" component={LoginPage} />
    <NoAuthRoute exact path="/reset-token" component={ForgotPasswordPage} />
    <NoAuthRoute
      exact
      path="/reset-password/:token"
      component={ResetPasswordPage}
    />
  </Switch>
)

export default Router
