import React, { FC, useMemo } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import MenuList from './MenuList'
import useStyles from './useStyles'

const SidebarMenu: FC = () => {
  const classes = useStyles()
  const paperProps = useMemo(
    () => ({
      elevation: 0,
      className: classes.paper,
    }),
    [classes.paper]
  )
  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      PaperProps={paperProps}
    >
      <Toolbar />
      <MenuList />
    </Drawer>
  )
}

export default SidebarMenu
