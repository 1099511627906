import { defaultState } from './reducer'
import type { MainHeaderRootState, MainHeaderState } from './types'
import { useSelector as useReduxSelector } from 'react-redux'

export const selector = ({
  mainHeader = defaultState,
}: MainHeaderRootState): MainHeaderState => mainHeader

export const useSelector = (): MainHeaderState => useReduxSelector(selector)

export default useSelector
