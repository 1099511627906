import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  item: {
    textTransform: 'unset',
    justifyContent: 'flex-start',
    color: theme.palette.primary.dark,
    opacity: 1,
    font: 'normal normal normal 16px/28px Lato',
  },
}))
