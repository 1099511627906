import {
  LogoutState,
  LogoutAction,
  LOGGING_OUT_BEGIN,
  LOGGING_OUT_SUCCESS,
  LOGGING_OUT_FAILED,
  RESET_ERROR,
  SET_LOADING,
} from './types'

export const defaultState: LogoutState = {
  loading: false,
  errorMessage: false,
}

const reducer = (state = defaultState, action: LogoutAction): LogoutState => {
  switch (action.type) {
    case LOGGING_OUT_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case LOGGING_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case LOGGING_OUT_FAILED:
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    case RESET_ERROR:
      return {
        ...state,
        errorMessage: false,
      }
    case SET_LOADING:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default reducer
