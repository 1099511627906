import React, { FC } from 'react'
import MuiSnackbar, {
  SnackbarProps as MuiSnackbarProps,
} from '@material-ui/core/Snackbar'
import Alert, { AlertProps } from '@material-ui/lab/Alert'
import useStyles from './useStyles'

export type SnackbarProps = MuiSnackbarProps &
  Pick<AlertProps, 'severity' | 'onClose'>

const Snackbar: FC<SnackbarProps> = ({
  message,
  severity,
  onClose,
  ...props
}: SnackbarProps) => {
  const classes = useStyles()
  return (
    <MuiSnackbar {...props} onClose={onClose}>
      <div className={classes.content}>
        <Alert severity={severity} onClose={onClose}>
          {message}
        </Alert>
      </div>
    </MuiSnackbar>
  )
}

Snackbar.defaultProps = {
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
}

export default Snackbar
