import React, { FC, Fragment } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import logo from 'logo.png'
import logo2x from 'logo@2x.png'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useSelector from './useSelector'
import { useDispatch } from 'react-redux'
import { togglePopover, resetError, loggingOutBegin } from './actions'
import { useInjectReducer } from 'hooks/useInjectReducer'
import reducer from './reducer'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Snackbar from 'components/Snackbar'
import ProgressOverlay from 'components/ProgressOverlay'
import saga from './saga'
import { useInjectSaga } from 'hooks/useInjectSaga'
import { useSession } from 'hooks/useSession'
import { useStyles } from './useStyles'

const MainHeader: FC = () => {
  useInjectSaga({ key: 'mainHeader', saga })
  useInjectReducer({ key: 'mainHeader', reducer })
  const classes = useStyles()
  const dispatch = useDispatch()
  const { popoverAnchorEl, errorMessage, loading } = useSelector()
  const user = useSession()
  const open = Boolean(popoverAnchorEl)
  const id = open ? 'user-popover' : undefined
  return (
    <AppBar elevation={4} position="fixed" className={classes.appBar}>
      <Toolbar>
        <Link component={RouterLink} to="/" className={classes.logo}>
          <img srcSet={`${logo2x} 2x, ${logo} 1x`} src={logo} alt="Lokakain" />
        </Link>
        <Box flexGrow={1} ml={2} />
        <Box ml={2}>
          {user && (
            <Fragment>
              <Box
                aria-describedby={id}
                onClick={(event) =>
                  dispatch(togglePopover(event.currentTarget))
                }
                className={classes.buttonPopover}
                clone
              >
                <ButtonBase>
                  <Avatar
                    classes={{
                      root: classes.avatar,
                    }}
                    src={user.imageUri || undefined}
                  />
                  <Typography variant="h6" color="inherit">
                    {user.email}
                  </Typography>
                </ButtonBase>
              </Box>
              <Menu
                id={id}
                open={open}
                anchorEl={popoverAnchorEl}
                getContentAnchorEl={null}
                onClose={() => dispatch(togglePopover(null))}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                classes={{ paper: classes.menuPaper }}
              >
                <MenuItem
                  component={RouterLink}
                  to="/account"
                  className={classes.menuItem}
                >
                  Account
                </MenuItem>
                <ProgressOverlay visible={loading} fullWidth>
                  <MenuItem
                    component="a"
                    onClick={() => dispatch(loggingOutBegin())}
                    className={classes.menuItem}
                  >
                    Logout
                  </MenuItem>
                </ProgressOverlay>
              </Menu>
            </Fragment>
          )}
        </Box>
      </Toolbar>
      {loading && (
        <LinearProgress color="secondary" className={classes.linearProgress} />
      )}
      <Snackbar
        severity="error"
        open={!!errorMessage}
        onClose={() => dispatch(resetError())}
      />
    </AppBar>
  )
}

export default MainHeader
