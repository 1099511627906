import React from 'react'
import { ReactReduxContext } from 'react-redux'
import getInjector, { Descriptor } from 'store/sagaInjectors'

interface Params extends Descriptor {
  /**
   * A key of the saga
   */
  readonly key: string
}

const useInjectSaga = ({ key, saga, mode }: Params): void => {
  const context = React.useContext(ReactReduxContext)
  React.useEffect(() => {
    const injectors = getInjector(context.store)
    injectors.injectSaga(key, { saga, mode })
    return () => {
      injectors.ejectSaga(key)
    }
  }, [key, saga, mode, context.store])
}

export { useInjectSaga }
