import {
  TOGGLE_POPOVER,
  LOGGING_OUT_BEGIN,
  LOGGING_OUT_SUCCESS,
  LOGGING_OUT_FAILED,
  RESET_ERROR,
  SET_LOADING,
  TogglePopover,
  LoggingOutBegin,
  LoggingOutSuccess,
  LoggingOutFailed,
  ResetError,
  SetLoading,
} from './types'

export const togglePopover = (
  popoverAnchorEl: HTMLElement | null
): TogglePopover => ({
  type: TOGGLE_POPOVER,
  payload: {
    popoverAnchorEl,
  },
})

export const loggingOutBegin = (): LoggingOutBegin => ({
  type: LOGGING_OUT_BEGIN,
})

export const loggingOutSuccess = (): LoggingOutSuccess => ({
  type: LOGGING_OUT_SUCCESS,
})

export const loggingOutFailed = (errorMessage: string): LoggingOutFailed => ({
  type: LOGGING_OUT_FAILED,
  payload: {
    errorMessage,
  },
})

export const resetError = (): ResetError => ({
  type: RESET_ERROR,
})

export const setLoading = (loading: boolean): SetLoading => ({
  type: SET_LOADING,
  payload: {
    loading,
  },
})
